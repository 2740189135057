import React from 'react';
import { motion } from 'framer-motion';

const NotFound = ({title}) => {
    return (
        <motion.div className="flex justify-center items-center h-screen w-full bg-gray-100 text-black text-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.2 } }}
        >
            <p className="font-sans text-2xl lg:text-3xl">
                <span className='text-red-500'>Market Closed Now</span><br/>{title}
            </p>

        </motion.div>
    );
};

export default NotFound;
