import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
//import TransporterVideo from '../../assets/video/transporter.mp4';
//import TruckGif from '../../assets/video/truck gif.gif';
import { motion } from 'framer-motion';

const cities = [
  "Ahmedabad", "Aizawl", "Akola", "Amalner", "Amravati", "Amritsar", "Anand", "Anantapur",
  "Anakapalle", "Aurangabad", "Azamgarh", "Baharampur", "Bagaha", "Balaghat", "Balurghat",
  "Baleshwar Town", "Barnala", "Baramula", "Baripada Town", "Barmer", "Bharatpur", "Bharuch",
  "Bhilai Nagar", "Bhilai", "Bhilwara", "Bhopal", "Bhuj", "Bhagalpur", "Bhadrak", "Bilaspur",
  "Bina", "Biraul", "Bokaro Steel City", "Bombay", "Brahmapur", "Chandausi", "Chandigarh",
  "Chilakaluripet", "Chittoor", "Coimbatore", "Cuttack", "Deesa", "Deoghar", "Dhanbad",
  "Dantewada", "Darbhanga", "Dimapur", "Dibrugarh", "Durg", "Dhule", "Dharmavaram", "Etawah",
  "Firozabad", "Firozpur", "Faridabad", "Faridkot", "Ganjbasoda", "Gandhinagar", "Guntur",
  "Gondia", "Gulbarga", "Gurgaon", "Guwahati", "Habra", "Hajipur", "Haldwani-cum-Kathgodam",
  "Hansi", "Hisar", "Hazaribag", "Hugli-Chinsurah", "Ichalkaranji", "Imphal", "Indore",
  "Jabalpur", "Jaisalmer", "Jammu", "Jamshedpur", "Jalgaon", "Jalpaiguri", "Jalandhar",
  "Jhansi", "Jhumri Tilaiya", "Jind", "Jorhat", "Jodhpur", "Kadapa", "Kakinada", "Kalyan-Dombivali",
  "Kamptee", "Kancheepuram", "Karimnagar", "Kashipur", "Kolkata", "Kolar", "Korba", "Kothagudem",
  "Kurnool", "Kozhikode", "Latur", "Lakhisarai", "Lakhimpur", "Ludhiana", "Loni", "Lucknow",
  "Madhubani", "Madurai", "Mahabubnagar", "Maharajganj", "Mangalore", "Mysore", "Madanapalle",
  "Malerkotla", "Mandya", "Mangaluru", "Mandsaur", "Marmagao", "Medinipur", "Muzaffarpur",
  "Moradabad", "Morvi", "Nagapattinam", "Nagda", "Nagpur", "Nanded", "Nanded-Waghala", "Nashik",
  "Nellore", "Neyveli (TS)", "New Delhi", "Noida", "Nagarcoil", "Navi Mumbai", "Ongole", "Orai",
  "Palakkad", "Pali", "Panchkula", "Panaji", "Panipat", "Parbhani", "Patan", "Pathankot",
  "Pudukkottai", "Purnia", "Purulia", "Raayachuru", "Rae Bareli", "Rajkot", "Rajampet",
  "Rajasthan", "Rajnandgaon", "Ramgarh", "Rampur", "Ranchi", "Ratlam", "Rewari", "Rudrapur",
  "Salem", "Sambalpur", "Sambhal", "Sangli", "Satna", "Saurashtra", "Siliguri", "Srinagar",
  "Shillong", "Shimla", "Shivamogga", "Shivpuri", "Sitapur", "Sitamarhi", "Suryapet",
  "Tadepalligudem", "Tadpatri", "Theni Allinagaram", "Thiruvananthapuram", "Tiruchirappalli",
  "Tirupati", "Tumkur", "Udhagamandalam", "Udaipur", "Udupi", "Ujjain", "Vadodara", "Valsad",
  "Varanasi", "Vapi", "Vellore", "Viluppuram", "Visakhapatnam", "Warangal", "Washim",
  "Wardha", "Yavatmal", "Yemmiganur", "Yamunanagar", "Zirakpur"
];

const vehicleTypes = [
  "22 Ft Container", "24 Ft Container", "32 Ft SXL Container", "32 Ft MXL Container", "34 Ft Container"
];

const Transporters = () => {
  const [transportName, setTransportName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [emailId, setEmailId] = useState('');
  const [fromCity, setFromCity] = useState('');
  const [toCity, setToCity] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [tonnage, setTonnage] = useState('');
  const [targetRate, setTargetRate] = useState('');

  const [fromCityOptions, setFromCityOptions] = useState(cities);
  const [toCityOptions, setToCityOptions] = useState(cities);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState(vehicleTypes);

  const [showFromCityDropdown, setShowFromCityDropdown] = useState(false);
  const [showToCityDropdown, setShowToCityDropdown] = useState(false);
  const [showVehicleTypeDropdown, setShowVehicleTypeDropdown] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [buttonText, setButtonText] = useState('Book');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fromCityDropdownRef = useRef(null);
  const toCityDropdownRef = useRef(null);
  const vehicleTypeDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        fromCityDropdownRef.current && !fromCityDropdownRef.current.contains(event.target) &&
        toCityDropdownRef.current && !toCityDropdownRef.current.contains(event.target) &&
        vehicleTypeDropdownRef.current && !vehicleTypeDropdownRef.current.contains(event.target)
      ) {
        setShowFromCityDropdown(false);
        setShowToCityDropdown(false);
        setShowVehicleTypeDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCitySearch = (input, setOptions, options, setShowDropdown) => {
    const filteredOptions = options.filter(option => option.toLowerCase().includes(input.toLowerCase()));
    setOptions(filteredOptions);
    setShowDropdown(true);
  };

  const handleOptionSelect = (option, setOption, setShowDropdown) => {
    setOption(option);
    setShowDropdown(false); // Close the dropdown
  };

  const closeAllDropdowns = () => {
    setShowFromCityDropdown(false);
    setShowToCityDropdown(false);
    setShowVehicleTypeDropdown(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText("Booking...");
    setIsSubmitting(true);

    const bookingData = {
      transportName,
      phoneNumber,
      emailId,
      fromCity,
      toCity,
      vehicleType,
      tonnage,
      targetRate,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_POST_URL}/api/bookings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bookingData),
      });

      if (response.ok) {
        setModalMessage('Your booking has been submitted successfully!');
        setIsError(false);
        setShowModal(true);
        resetForm();
      } else {
        setModalMessage('Failed to submit booking.');
        setIsError(true);
        setShowModal(true);
      }
    } catch (error) {
      console.error('Error submitting booking:', error);
      setModalMessage('An error occurred while submitting your booking.');
      setIsError(true);
      setShowModal(true);
    } finally {
      setButtonText("Book");
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    setTransportName('');
    setPhoneNumber('');
    setEmailId('');
    setFromCity('');
    setToCity('');
    setVehicleType('');
    setTonnage('');
    setTargetRate('');
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <motion.div
      className="container-fluid"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.2 } }}
    >
      <Helmet>
        <title>Go Trans Logistics - Transporters</title>
        <meta name="description" content="Your Cargo, Our Expertise - Utilize our extensive network of certified truckers to transport your goods with full real-time tracking and dedicated 24/7 customer support." />
        <link rel="canonical" href="https://gotranslogistics.com/transporters" />
      </Helmet>
      {/* Video background */}
      <div className="relative w-full h-screen overflow-hidden">
        <video autoPlay loop muted playsInline className="absolute top-0 left-0 p-2 w-full h-full object-cover">
          <source src={"https://res.cloudinary.com/di6sgq793/video/upload/v1729523823/transporter_nxywnq.mp4"} type="video/mp4" />
        </video>

        {/* Overlay Text */}
        <div className="absolute inset-0 flex flex-col lg:flex-row justify-center lg:justify-between items-center lg:items-center text-center lg:text-left px-4 md:px-0 w-full max-w-7xl mx-auto lg:top-1/4 lg:mt-60">
          {/* Text */}
          <div className="lg:w-2/3 text-left space-y-4 lg:space-y-0">
            <h1 className="text-white text-4xl md:text-5xl lg:text-6xl font-bold animate__animated animate__fadeInUp">
              Your Cargo,
            </h1>
            <h1 className="text-white text-4xl md:text-5xl lg:text-6xl font-bold animate__animated animate__fadeInUp">
              Our Expertise
            </h1>
            <p className="text-yellow-400 text-md md:text-lg lg:text-xl mt-4 lg:max-w-lg">
              Utilize our extensive network of certified truckers to transport your goods with full real-time tracking and dedicated 24/7 customer support.
            </p>
          </div>

          {/* Button */}
          <div className="mt-6 lg:mt-0 lg:ml-auto">
            {/* Smooth scroll to form */}
            <a
              className="relative bg-black text-white py-3 px-8 text-lg md:text-xl font-bold border-2 border-yellow-500 overflow-hidden transition-all duration-300 group hover:bg-white hover:text-black hover:border-black cursor-pointer"
              href="#transporters"
              onClick={(e) => {
                e.preventDefault();
                document.querySelector('#transporters').scrollIntoView({ behavior: 'smooth' });
              }}
            >
              <span className="relative z-10">Book a Truck</span>
              <div className="absolute inset-0 bg-white w-0 h-full transition-all duration-300 ease-in-out group-hover:w-full"></div>
            </a>
          </div>
        </div>
      </div>

      {/* Form Section */}
      <section className="w-full py-10 bg-transparent" id="transporters">
        <div className="relative flex flex-col md:flex-row items-start md:items-center justify-center max-w-7xl mx-auto p-6 bg-transparent space-x-4">
          {/* Form */}
          <div className="relative z-20 w-full md:w-1/2 bg-transparent p-6">
            <h2 className="font-bold mb-6 text-3xl lg:text-6xl">Book Your Truck</h2>
            <form onSubmit={handleSubmit} className="space-y-5">
              <input
                className="w-full p-4 bg-transparent border-2 border-black text-black placeholder-black rounded-md focus:outline-none focus:ring-0 focus:border-black z-20"
                id="transportName"
                type="text"
                placeholder="Transport Name"
                value={transportName}
                onChange={(e) => setTransportName(e.target.value)}
                required
              />
              <input
                className="w-full p-4 bg-transparent border-2 border-black text-black placeholder-black rounded-md focus:outline-none focus:ring-0 focus:border-black z-20"
                id="phoneNumber"
                type="tel"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                minLength={10}
                maxLength={10}
                required
              />
              <input
                className="w-full p-4 bg-transparent border-2 border-black text-black placeholder-black rounded-md focus:outline-none focus:ring-0 focus:border-black z-20"
                id="emailId"
                type="email"
                placeholder="Email Address"
                value={emailId}
                onChange={(e) => setEmailId(e.target.value)}
                required
              />

              {/* From City Dropdown */}
              <div className="relative" ref={fromCityDropdownRef}>
                <input
                  className="w-full p-4 bg-transparent border-2 border-black text-black placeholder-black rounded-md focus:outline-none focus:ring-0 focus:border-black z-20"
                  type="text"
                  placeholder="From City"
                  value={fromCity}
                  onChange={(e) => {
                    setFromCity(e.target.value);
                    handleCitySearch(e.target.value, setFromCityOptions, cities, setShowFromCityDropdown);
                  }}
                  onFocus={() => {
                    closeAllDropdowns();
                    setShowFromCityDropdown(true);
                  }}
                  required
                />
                {showFromCityDropdown && (
                  <div className="absolute z-50 bg-white border-2 border-gray-300 rounded-md w-full mt-1 max-h-48 overflow-y-auto">
                    {fromCityOptions.map((city, index) => (
                      <div
                        key={index}
                        className="p-2 hover:bg-gray-200 cursor-pointer border-b border-gray-300 last:border-0"
                        onClick={() => handleOptionSelect(city, setFromCity, setShowFromCityDropdown)}
                      >
                        {city}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* To City Dropdown */}
              <div className="relative " ref={toCityDropdownRef}>
                <input
                  className="w-full p-4 bg-transparent border-2 border-black text-black placeholder-black rounded-md focus:outline-none focus:ring-0 focus:border-black z-20"
                  type="text"
                  placeholder="To City"
                  value={toCity}
                  onChange={(e) => {
                    setToCity(e.target.value);
                    handleCitySearch(e.target.value, setToCityOptions, cities, setShowToCityDropdown);
                  }}
                  onFocus={() => {
                    closeAllDropdowns();
                    setShowToCityDropdown(true);
                  }}
                  required
                />
                {showToCityDropdown && (
                  <div className="absolute z-50 bg-white border-2 border-gray-300 rounded-md w-full mt-1 max-h-48 overflow-y-auto">
                    {toCityOptions.map((city, index) => (
                      <div
                        key={index}
                        className="p-2 hover:bg-gray-200 cursor-pointer border-b border-gray-300 last:border-0"
                        onClick={() => handleOptionSelect(city, setToCity, setShowToCityDropdown)}
                      >
                        {city}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* Vehicle Type Dropdown */}
              <div className="relative" ref={vehicleTypeDropdownRef}>
                <input
                  className="w-full p-4 bg-transparent border-2 border-black text-black placeholder-black rounded-md focus:outline-none focus:ring-0 focus:border-black z-20"
                  type="text"
                  placeholder="Select Vehicle Type"
                  value={vehicleType}
                  onChange={(e) => {
                    setVehicleType(e.target.value);
                    handleCitySearch(e.target.value, setVehicleTypeOptions, vehicleTypes, setShowVehicleTypeDropdown);
                  }}
                  onFocus={() => {
                    closeAllDropdowns();
                    setShowVehicleTypeDropdown(true);
                  }}
                  required
                />
                {showVehicleTypeDropdown && (
                  <div className="absolute z-50 bg-white border-2 border-gray-300 rounded-md w-full mt-1 max-h-48 overflow-y-auto">
                    {vehicleTypeOptions.map((type, index) => (
                      <div
                        key={index}
                        className="p-2 hover:bg-gray-200 cursor-pointer border-b border-gray-300 last:border-0"
                        onClick={() => handleOptionSelect(type, setVehicleType, setShowVehicleTypeDropdown)}
                      >
                        {type}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <input
                className="w-full p-4 bg-transparent border-2 border-black text-black placeholder-black rounded-md focus:outline-none focus:ring-0 focus:border-black z-20"
                id="tonnage"
                type="number"
                placeholder="Tonnage"
                value={tonnage}
                onChange={(e) => setTonnage(e.target.value)}
                required
                min={0}
                max={25}
              />
              <input
                className="w-full p-4 bg-transparent border-2 border-black text-black placeholder-black rounded-md focus:outline-none focus:ring-0 focus:border-black z-20"
                id="targetRate"
                type="text"
                placeholder="Target Rate (INR)"
                value={targetRate}
                onChange={(e) => setTargetRate(e.target.value)}
                required
              />
              <button
                className="relative w-1/3 py-4 bg-white text-black font-bold border-2 border-black overflow-hidden transition-all ease-in-out group hover:bg-white hover:text-white"
                type="submit"
                disabled={isSubmitting}
              >
                <span className="relative z-10">{buttonText}</span>
                <div className="absolute inset-0 bg-black w-0 h-full transition-all duration-300 ease-in-out group-hover:w-full"></div>
              </button>
            </form>
          </div>

          {/* GIF */}
          <div className="md:w-1/2 mt-10 md:mt-0 md:ml-10">
            <img
              src={"https://res.cloudinary.com/di6sgq793/image/upload/v1729523823/truck_gif_tk69by.gif"}
              alt="Truck GIF"
              className="w-full max-w-md md:max-w-lg lg:max-w-xl absolute md:relative left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 md:top-auto md:left-auto md:transform-none z-10 md:z-0 opacity-30 md:opacity-100"
            />
          </div>
        </div>
      </section>


      {/* Modal for success or error message */}
      {showModal && (
        <div className="fixed inset-0 bg-opacity-90 flex items-center justify-center z-50">
          {/* Blur effect on background */}
          <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm"></div>

          {/* Modal content */}
          <div className="relative z-60 mt-80 transform translate-y-1/2 lg:translate-y-60  translate-y-1/2 lg:w-1/4 w-3/4 bg-white p-6 space-y-4 text-center shadow-lg">
            <h3 className={`${isError ? 'text-red-500' : 'text-green-500'} text-xl font-bold`}>
              {isError ? 'Error' : 'Success'}
            </h3>
            <p className="text-lg">{modalMessage}</p>
            <button className="mt-4 py-2 px-4 bg-black text-white hover:bg-gray-800 transition hover:text-black hover:bg-white hover:border-2 hover:border-black" onClick={handleCloseModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default Transporters;