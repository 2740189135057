import {React, useEffect} from 'react';
import Navbar from './components/Navbar';
import { AnimatePresence } from 'framer-motion';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from "react-router-dom";
import ReactGA from "react-ga4";
import Home from './components/pages/Home';
import LiveLoads from './components/pages/LiveLoads2.0';
import LiveTrucks from './components/pages/LiveTrucks';
import Transporters from './components/pages/Transporters';
import Truckers from './components/pages/Truckers';
import ContactUs from './components/pages/ContactUs';
import PrivacyandPolicy from './components/pages/PrivacyandPolicy';
import TermsConditions from './components/pages/Terms&Conditions';
import ComingSoon from './components/pages/ComingSoon';
import Footer from './components/Footer';
import NotFound from './components/pages/404Error';
import './App.css';



const MainContent = () => {
  const location = useLocation();

  const isNotFoundPage = location.pathname === "/404";

  useEffect(() => {
    // Initialize Google Analytics only once
    ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
  }, []);

  useEffect(() => {
    // Track page view on every route change
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  return (
    <>
      {!isNotFoundPage && <Navbar />}
      <main className="main-content">
        <AnimatePresence>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Home />} />
            <Route path="/liveloads" element={<LiveLoads />} />
            <Route path="/livetrucks" element={<LiveTrucks />} />
            <Route path="/transporters" element={<Transporters />} />
            <Route path="/truckers" element={<Truckers />} />
            <Route path="/aboutus" element={<ComingSoon />} />
            <Route path="/news" element={<ComingSoon />} /> 
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/privacyandpolicy" element={<PrivacyandPolicy />} />
            <Route path="/termsandconditions" element={<TermsConditions />} />
            <Route path="*" element={<Navigate to="/404" replace />} />
            <Route path="/404" element={<NotFound />} />
          </Routes>
        </AnimatePresence>
      </main>
      {!isNotFoundPage && <Footer />}
    </>
  );
};


const App = () => {
  return (
    <Router>
      <MainContent />
    </Router>
  );
};

export default App;