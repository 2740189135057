import React from 'react';
import { motion } from 'framer-motion';

const ComingSoon = () => {
    return (
        <motion.div className="flex justify-center items-center h-screen w-full bg-gray-100 text-black text-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.2 } }}
        >
            <h1 className="font-sans text-4xl md:text-7xl lg:text-8xl">
                Coming Soon<span className="dot animate-blink">.</span>
                <span className="dot animate-blink delay-200">.</span>
                <span className="dot animate-blink delay-400">.</span>
            </h1>
        </motion.div>
    );
};

export default ComingSoon;
