import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
//import TruckGif from '../../assets/video/truck gif.gif';
//import Trucker from '../../assets/img/trucker.jpg';
// import { useLocation } from 'react-router-dom';

const cities = [
  "Ahmedabad", "Aizawl", "Akola", "Amalner", "Amravati", "Amritsar", "Anand", "Anantapur",
  "Anakapalle", "Aurangabad", "Azamgarh", "Baharampur", "Bagaha", "Balaghat", "Balurghat",
  "Baleshwar Town", "Barnala", "Baramula", "Baripada Town", "Barmer", "Bharatpur", "Bharuch",
  "Bhilai Nagar", "Bhilai", "Bhilwara", "Bhopal", "Bhuj", "Bhagalpur", "Bhadrak", "Bilaspur",
  "Bina", "Biraul", "Bokaro Steel City", "Bombay", "Brahmapur", "Chandausi", "Chandigarh",
  "Chilakaluripet", "Chittoor", "Coimbatore", "Cuttack", "Deesa", "Deoghar", "Dhanbad",
  "Dantewada", "Darbhanga", "Dimapur", "Dibrugarh", "Durg", "Dhule", "Dharmavaram", "Etawah",
  "Firozabad", "Firozpur", "Faridabad", "Faridkot", "Ganjbasoda", "Gandhinagar", "Guntur",
  "Gondia", "Gulbarga", "Gurgaon", "Guwahati", "Habra", "Hajipur", "Haldwani-cum-Kathgodam",
  "Hansi", "Hisar", "Hazaribag", "Hugli-Chinsurah", "Ichalkaranji", "Imphal", "Indore",
  "Jabalpur", "Jaisalmer", "Jammu", "Jamshedpur", "Jalgaon", "Jalpaiguri", "Jalandhar",
  "Jhansi", "Jhumri Tilaiya", "Jind", "Jorhat", "Jodhpur", "Kadapa", "Kakinada", "Kalyan-Dombivali",
  "Kamptee", "Kancheepuram", "Karimnagar", "Kashipur", "Kolkata", "Kolar", "Korba", "Kothagudem",
  "Kurnool", "Kozhikode", "Latur", "Lakhisarai", "Lakhimpur", "Ludhiana", "Loni", "Lucknow",
  "Madhubani", "Madurai", "Mahabubnagar", "Maharajganj", "Mangalore", "Mysore", "Madanapalle",
  "Malerkotla", "Mandya", "Mangaluru", "Mandsaur", "Marmagao", "Medinipur", "Muzaffarpur",
  "Moradabad", "Morvi", "Nagapattinam", "Nagda", "Nagpur", "Nanded", "Nanded-Waghala", "Nashik",
  "Nellore", "Neyveli (TS)", "New Delhi", "Noida", "Nagarcoil", "Navi Mumbai", "Ongole", "Orai",
  "Palakkad", "Pali", "Panchkula", "Panaji", "Panipat", "Parbhani", "Patan", "Pathankot",
  "Pudukkottai", "Purnia", "Purulia", "Raayachuru", "Rae Bareli", "Rajkot", "Rajampet",
  "Rajasthan", "Rajnandgaon", "Ramgarh", "Rampur", "Ranchi", "Ratlam", "Rewari", "Rudrapur",
  "Salem", "Sambalpur", "Sambhal", "Sangli", "Satna", "Saurashtra", "Siliguri", "Srinagar",
  "Shillong", "Shimla", "Shivamogga", "Shivpuri", "Sitapur", "Sitamarhi", "Suryapet",
  "Tadepalligudem", "Tadpatri", "Theni Allinagaram", "Thiruvananthapuram", "Tiruchirappalli",
  "Tirupati", "Tumkur", "Udhagamandalam", "Udaipur", "Udupi", "Ujjain", "Vadodara", "Valsad",
  "Varanasi", "Vapi", "Vellore", "Viluppuram", "Visakhapatnam", "Warangal", "Washim",
  "Wardha", "Yavatmal", "Yemmiganur", "Yamunanagar", "Zirakpur"
];

const containerTypes = [
  "22 Ft Container", "24 Ft Container", "32 Ft SXL Container", "32 Ft MXL Container", "34 Ft Container"
];


const Truckers = () => {
  // const location = useLocation();
  const [truckerName, setTruckerName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  const [loadFromCity, setLoadFromCity] = useState("");
  const [loadFromCityOptions, setLoadFromCityOptions] = useState(cities);

  const [loadToCity, setLoadToCity] = useState("");
  const [loadToCityOptions, setLoadToCityOptions] = useState(cities);

  const [currentCity, setCurrentCity] = useState("");
  const [currentCityOptions, setCurrentCityOptions] = useState(cities);

  const [containerType, setContainerType] = useState("");
  const [containerTypeOptions, setContainerTypeOptions] = useState(containerTypes);

  const [capacity, setCapacity] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isError, setIsError] = useState(false);

  // State for dropdown visibility
  const [showLoadFromCityDropdown, setShowLoadFromCityDropdown] = useState(false);
  const [showLoadToCityDropdown, setShowLoadToCityDropdown] = useState(false);
  const [showCurrentCityDropdown, setShowCurrentCityDropdown] = useState(false);
  const [showContainerTypeDropdown, setShowContainerTypeDropdown] = useState(false);

  // Button text state
  const [buttonText, setButtonText] = useState("Load");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Refs for handling dropdown clicks outside
  const loadFromCityDropdownRef = useRef(null);
  const loadToCityDropdownRef = useRef(null);
  const currentCityDropdownRef = useRef(null);
  const containerTypeDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        loadFromCityDropdownRef.current && !loadFromCityDropdownRef.current.contains(event.target) &&
        loadToCityDropdownRef.current && !loadToCityDropdownRef.current.contains(event.target) &&
        currentCityDropdownRef.current && !currentCityDropdownRef.current.contains(event.target) &&
        containerTypeDropdownRef.current && !containerTypeDropdownRef.current.contains(event.target)
      ) {
        closeAllDropdowns();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCitySearch = (input, setOptions, options, setShowDropdown) => {
    const filteredOptions = options.filter((option) =>
      option.toLowerCase().includes(input.toLowerCase())
    );
    setOptions(filteredOptions);
    setShowDropdown(true);
  };

  const handleOptionSelect = (option, setOption, setShowDropdown) => {
    setOption(option);
    setShowDropdown(false);
  };

  const closeAllDropdowns = () => {
    setShowLoadFromCityDropdown(false);
    setShowLoadToCityDropdown(false);
    setShowCurrentCityDropdown(false);
    setShowContainerTypeDropdown(false);
  };

  const resetForm = () => {
    setTruckerName("");
    setPhoneNumber("");
    setEmail("");
    setLoadFromCity("");
    setLoadToCity("");
    setCurrentCity("");
    setContainerType("");
    setCapacity("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText("Loading...");
    setIsSubmitting(true);

    const loadingData = {
      truckerName,
      phoneNumber,
      email,
      loadFromCity,
      loadToCity,
      currentCity,
      containerType,
      capacity,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_POST_URL}/api/loadings`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loadingData),
      });

      if (response.ok) {
        setModalMessage("Your Load has been submitted successfully!");
        setIsError(false);
      } else {
        setModalMessage("Failed to submit Load.");
        setIsError(true);
      }

      setShowModal(true);
      resetForm();
    } catch (error) {
      console.error("Error submitting booking:", error);
      setModalMessage("An error occurred while submitting your Load.");
      setShowModal(true);
      setIsError(true);
    }

    setButtonText("Load");
    setIsSubmitting(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // useEffect(() => {
  //   if (location.hash === '#truckers') {
  //     const section = document.getElementById('truckers');
  //     if (section) {
  //       section.scrollIntoView({ behavior: 'smooth' });
  //     }
  //   }
  // }, [location]);


  return (
    <motion.div
      className="container-fluid"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.2 } }}
    >
      <Helmet>
        <title>Go Trans Logistics - Truckers</title>
        <meta name="description" content="Reliable Loads, More Profits - GOTL ensures higher earnings on every trip by providing consistent loads and timely payment settlements, all with zero commission fees." />
        <link rel="canonical" href="https://gotranslogistics.com/truckers" />
      </Helmet>
      {/* Image Container */}
      <div className="relative w-full h-screen overflow-hidden">
        <img src={"https://res.cloudinary.com/di6sgq793/image/upload/v1729525817/trucker_9000_ywvlkh.jpg"} alt="Truckers" className="absolute top-0 left-0 p-2 w-full h-full object-cover" />

        {/* Overlay Text */}
        <div className="absolute inset-0 flex flex-col lg:flex-row justify-center lg:justify-between items-center lg:items-center text-center lg:text-left px-4 md:px-0 w-full max-w-7xl mx-auto lg:top-1/4 lg:mt-60">
          {/* Text */}
          <div className="lg:w-2/3 text-left space-y-4 lg:space-y-0">
            <h1 className="text-white text-4xl md:text-5xl lg:text-6xl font-bold animate__animated animate__fadeInUp">
              Reliable Loads,
            </h1>
            <h1 className="text-white text-4xl md:text-5xl lg:text-6xl font-bold animate__animated animate__fadeInUp">
              More Profits
            </h1>
            <p className="text-yellow-400 text-md md:text-lg lg:text-xl mt-4 lg:max-w-lg">
              GOTL ensures higher earnings on every trip by providing consistent loads and timely payment settlements, all with zero commission fees.
            </p>
          </div>

          {/* Button */}
          <div className="mt-6 lg:mt-0 lg:ml-auto">
            <a className="bg-transparent text-white py-3 px-8 text-lg md:text-xl font-bold border-2 border-white"
              href="#truckers"
              onClick={(e) => {
                e.preventDefault();
                document.querySelector('#truckers').scrollIntoView({ behavior: 'smooth' });
              }}
            >
              Find a Load
            </a>
          </div>
        </div>
      </div>

      {/* Form Section */}
      <section className="w-full py-10 bg-transparent" id="truckers">
        <div className="relative flex flex-col md:flex-row items-start md:items-center justify-center max-w-7xl mx-auto p-6 bg-transparent space-x-4">
          {/* Form */}
          <div className="relative z-20 w-full md:w-1/2 bg-transparent p-6">
            <h2 className="font-bold mb-6 text-3xl lg:text-6xl">Load Your Truck</h2>
            <form onSubmit={handleSubmit} className="space-y-5">
              <input
                className="w-full p-4 bg-transparent border-2 border-black text-black placeholder-black rounded-md focus:outline-none focus:ring-0 focus:border-black"
                id="truckerName"
                type="text"
                placeholder="Trucker Name"
                value={truckerName}
                onChange={(e) => setTruckerName(e.target.value)}
                required
              />
              <input
                className="w-full p-4 bg-transparent border-2 border-black text-black placeholder-black rounded-md focus:outline-none focus:ring-0 focus:border-black"
                id="phoneNumber"
                type="tel"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                minLength={10}
                maxLength={10}
                required
              />
              <input
                className="w-full p-4 bg-transparent border-2 border-black text-black placeholder-black rounded-md focus:outline-none focus:ring-0 focus:border-black"
                id="email"
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />

              {/* Load From City Dropdown */}
              <div className="relative" ref={loadFromCityDropdownRef}>
                <input
                  className="w-full p-4 bg-transparent border-2 border-black text-black placeholder-black rounded-md focus:outline-none focus:ring-0 focus:border-black z-40 relative"
                  type="text"
                  placeholder="Load From City"
                  value={loadFromCity}
                  onChange={(e) => {
                    setLoadFromCity(e.target.value);
                    handleCitySearch(e.target.value, setLoadFromCityOptions, cities, setShowLoadFromCityDropdown);
                  }}
                  onFocus={() => {
                    closeAllDropdowns();
                    setShowLoadFromCityDropdown(true);
                  }}
                  required
                />
                {showLoadFromCityDropdown && loadFromCityOptions.length > 0 && (
                  <div className="absolute z-50 bg-white border-2 border-gray-300 rounded-md w-full mt-1 max-h-48 overflow-y-auto">
                    {loadFromCityOptions.map((city, index) => (
                      <div
                        key={index}
                        className="p-2 hover:bg-gray-200 cursor-pointer border-b border-gray-300 last:border-0"
                        onClick={() => handleOptionSelect(city, setLoadFromCity, setShowLoadFromCityDropdown)}
                      >
                        {city}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* Load To City Dropdown */}
              <div className="relative " ref={loadToCityDropdownRef}>
                <input
                  className="w-full p-4 bg-transparent border-2 border-black text-black placeholder-black rounded-md focus:outline-none focus:ring-0 focus:border-black z-40 relative"
                  type="text"
                  placeholder="Load To City"
                  value={loadToCity}
                  onChange={(e) => {
                    setLoadToCity(e.target.value);
                    handleCitySearch(e.target.value, setLoadToCityOptions, cities, setShowLoadToCityDropdown);
                  }}
                  onFocus={() => {
                    closeAllDropdowns();
                    setShowLoadToCityDropdown(true);
                  }}
                  required
                />
                {showLoadToCityDropdown && loadToCityOptions.length > 0 && (
                  <div className="absolute z-50 bg-white border-2 border-gray-300 rounded-md w-full mt-1 max-h-48 overflow-y-auto">
                    {loadToCityOptions.map((city, index) => (
                      <div
                        key={index}
                        className="p-2 hover:bg-gray-200 cursor-pointer border-b border-gray-300 last:border-0"
                        onClick={() => handleOptionSelect(city, setLoadToCity, setShowLoadToCityDropdown)}
                      >
                        {city}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* Current City Dropdown */}
              <div className="relative" ref={currentCityDropdownRef}>
                <input
                  className="w-full p-4 bg-transparent border-2 border-black text-black placeholder-black rounded-md focus:outline-none focus:ring-0 focus:border-black z-40 relative"
                  type="text"
                  placeholder="Current City"
                  value={currentCity}
                  onChange={(e) => {
                    setCurrentCity(e.target.value);
                    handleCitySearch(e.target.value, setCurrentCityOptions, cities, setShowCurrentCityDropdown);
                  }}
                  onFocus={() => {
                    closeAllDropdowns();
                    setShowCurrentCityDropdown(true);
                  }}
                  required
                />
                {showCurrentCityDropdown && currentCityOptions.length > 0 && (
                  <div className="absolute z-50 bg-white border-2 border-gray-300 rounded-md w-full mt-1 max-h-48 overflow-y-auto">
                    {currentCityOptions.map((city, index) => (
                      <div
                        key={index}
                        className="p-2 hover:bg-gray-200 cursor-pointer border-b border-gray-300 last:border-0"
                        onClick={() => handleOptionSelect(city, setCurrentCity, setShowCurrentCityDropdown)}
                      >
                        {city}
                      </div>
                    ))}
                  </div>
                )}
              </div>


              {/* Container Type Dropdown */}
              <div className="relative" ref={containerTypeDropdownRef}>
                <input
                  className="w-full p-4 bg-transparent border-2 border-black text-black placeholder-black rounded-md focus:outline-none focus:ring-0 focus:border-black"
                  type="text"
                  placeholder="Container Type"
                  value={containerType}
                  onChange={(e) => {
                    setContainerType(e.target.value);
                    handleCitySearch(e.target.value, setContainerTypeOptions, containerTypes, setShowContainerTypeDropdown);
                  }}
                  onFocus={() => {
                    closeAllDropdowns();
                    setShowContainerTypeDropdown(true);
                  }}
                  required
                />
                {showContainerTypeDropdown && containerTypeOptions.length > 0 && (
                  <div className="absolute z-50 bg-white border-2 border-gray-300 rounded-md w-full mt-1 max-h-48 overflow-y-auto">
                    {containerTypeOptions.map((type, index) => (
                      <div
                        key={index}
                        className="p-2 hover:bg-gray-200 cursor-pointer border-b border-gray-300 last:border-0"
                        onClick={() => handleOptionSelect(type, setContainerType, setShowContainerTypeDropdown)}
                      >
                        {type}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* Capacity Input */}
              <input
                className="w-full p-4 bg-transparent border-2 border-black text-black placeholder-black rounded-md focus:outline-none focus:ring-0 focus:border-black"
                id="capacity"
                type="number"
                placeholder="Capacity (Tons)"
                value={capacity}
                onChange={(e) => setCapacity(e.target.value)}
                min={1}
                max={25}
                required
              />
              <button
                className="relative w-1/3 py-4 bg-white text-black font-bold border-2 border-black overflow-hidden transition-all ease-in-out group hover:bg-white hover:text-white"
                type="submit"
                disabled={isSubmitting}
              >
                <span className="relative z-10">{buttonText}</span>
                <div className="absolute inset-0 bg-black w-0 h-full transition-all duration-300 ease-in-out group-hover:w-full"></div>
              </button>
            </form>
          </div>

          {/* GIF */}
          <div className="md:w-1/2 mt-10 md:mt-0 md:ml-10">
            <img
              src={"https://res.cloudinary.com/di6sgq793/image/upload/v1729523823/truck_gif_tk69by.gif"}
              alt="Truck GIF"
              className="w-full max-w-md md:max-w-lg lg:max-w-xl absolute md:relative left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 md:top-auto md:left-auto md:transform-none z-10 md:z-0 opacity-30 md:opacity-100"
            />
          </div>
        </div>
      </section>

      {/* Modal for success or error message */}
      {showModal && (
        <div className="fixed inset-0 bg-opacity-90 flex items-center justify-center z-50">
          {/* Blur effect on background */}
          <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm"></div>

          {/* Modal content */}
          <div className="relative z-60 mt-80 transform translate-y-1/2 lg:translate-y-60  translate-y-1/2 lg:w-1/4 w-3/4 bg-white p-6 space-y-4 text-center shadow-lg">
            <h3 className={`${isError ? 'text-red-500' : 'text-green-500'} text-xl font-bold`}>
              {isError ? 'Error' : 'Success'}
            </h3>
            <p className="text-lg">{modalMessage}</p>
            <button className="mt-4 py-2 px-4 bg-black text-white hover:bg-gray-800 transition hover:text-black hover:bg-white hover:border-2 hover:border-black" onClick={handleCloseModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default Truckers;
