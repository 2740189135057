import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

function PrivacyPolicy () {
    return (
        <motion.div className="mt-20 mx-auto mb-5 max-w-6xl px-4 sm:px-6 lg:px-8 py-10 bg-[#f9f9f9]" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0, transition: { duration: 0.2 } }}>
            <Helmet>
                <title>Go Trans Logistics - Privacy and Policy</title>
                <meta name="description" content="Go Trans Logistics (GOTL) gathers information about you when you interact with our website or engage with our services (collectively referred to as the 'Services'). This data collection occurs both when you visit our website and during other interactions and communications related to the Services." />
                <link rel="canonical" href="https://gotranslogistics.in/privacyandpolicy" />
            </Helmet>
            <div className="bg-white p-6 rounded-lg shadow-lg">
                <h1 className="text-3xl sm:text-4xl font-bold text-center text-gray-800 mb-6">Privacy Policy</h1>
                <p className="text-base leading-7 text-gray-700 mb-6 text-justify">
                    Go Trans Logistics (GOTL) gathers information about you when you interact with our website or engage with our services (collectively referred to as the "Services"). This data collection occurs both when you visit our website and during other interactions and communications related to the Services.
                </p>

                <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Scope and Application</h2>
                <p className="text-base leading-7 text-gray-700 mb-6 text-justify">
                    This Privacy Policy (referred to as "Policy") applies to all users of our Services (collectively "Users," or individually "you"), including GOTL transporters, truckers, commercial drivers, shippers, and dispatchers. It does not extend to any other application, website, platform, or other product or service provided by Go Trans Logistics or its subsidiaries and affiliates (collectively, "GOTL").
                </p>

                <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Collection of Information</h2>
                <h6 className="text-lg font-semibold text-gray-700 mb-2">Information You Provide to Us</h6>
                <p className="text-base leading-7 text-gray-700 mb-6 text-justify">
                    We collect information that you provide directly to us, such as when you create, modify, or log in to your account, request support, or communicate with us in other ways. If you use the Services as a commercial truck owner or logistics service provider, this may include submitting your driver's license, vehicle information, insurance details, or other related data. The information we collect may include your name, email, phone number, postal address, profile picture, payment details, vehicle registration information, insurance information, driver's license details, and any other information you choose to provide.
                </p>

                <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Call and SMS Data</h2>
                <p className="text-base leading-7 text-gray-700 mb-6 text-justify">Our Services enable communication between Users, such as through phone calls or SMS messages. To facilitate this, we may collect call data, including the date and time of the call or SMS message, the phone numbers of the parties involved, and the content of the SMS message.</p>

                <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Log Information</h2>
                <p className="text-base leading-7 text-gray-700 mb-6 text-justify">When you interact with the Services, we collect server logs that may contain information such as your device's IP address, access dates and times, app features or pages viewed, app crashes and other system activities, browser type, and the third-party site or service you were using before engaging with our Services.</p>

                <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Information We Collect From Other Sources</h2>
                <p className="text-base leading-7 text-gray-700 mb-6 text-justify">We may also obtain information from external sources and integrate it with the data we collect through the Services. For instance, if you are a commercial driver or truck owner, we might receive details about you from carriers or logistics service providers. Additionally, as a commercial driver, we could gather information from other Users, such as carriers or shippers, or from other third parties. This can include data about your location, driver rating, or other assessments of the services you provide.</p>

                <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Use of Information</h2>
                <p className="text-base leading-7 text-gray-700 mb-6 text-justify">We may use the information we collect about you for various purposes, including: providing, maintaining, and improving the Services (such as facilitating payments, sending receipts, offering products and services, and developing new features); tracking the status and location of shipments and assessing your usage of the Services; facilitating communication between you and other users, including estimated delivery times; sending you updates about products, services, shipments, promotions, news, and events that may interest you, where allowed by local laws; and personalizing and enhancing the Services by recommending shipments, features, content, social connections, referrals, and advertisements. We will ensure that appropriate measures are taken to protect your personal information in accordance with this Policy.</p>

                <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Sharing of Information</h2>
                <p className="text-base leading-7 text-gray-700 mb-6 text-justify">We may share the information we collect about you as outlined in this Policy or as specified at the time of collection or sharing, including in the following ways:</p>

                <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Through Our Services</h2>
                <p className="text-base leading-7 text-gray-700 mb-6 text-justify">We may share your information in several ways. This includes sharing with other Users or third parties who need it for transportation services arranged by GOTL, such as your name, personal photo, vehicle details, and location, depending on applicable laws. We may also share information as directed by you for specific services, offers, or promotions, or with third parties to fulfill services you requested through partnerships or promotional offers. Additionally, if you post content in public forums like blog comments or social media, it may be visible to the general public. Finally, we may share information with third parties you authorize, such as other apps or websites that integrate with our API or Services, or those with which we have integrations.</p>

                <h6 className="text-lg font-semibold text-gray-700 mb-2">Access, Correction and Deletion Rights</h6>
                <p className="text-base leading-7 text-gray-700 mb-6 text-justify">
                    GOTL will honor requests from individuals concerning access to, correction of, or deletion of their personal data stored by the company, in compliance with applicable laws.
                </p>

                <h6 className="text-lg font-semibold text-gray-700 mb-2">Contacts Information</h6>
                <p className="text-base leading-7 text-gray-700 mb-6 text-justify">
                    We may also request permission to collect and sync contact information from your device, following the permission system of your mobile operating system.
                </p>

                <h6 className="text-lg font-semibold text-gray-700 mb-2">Promotional Communications</h6>
                <p className="text-base leading-7 text-gray-700 mb-6 text-justify">
                    You can choose to stop receiving promotional messages from us by following the instructions provided in those messages. However, even if you opt out, we may still send you non-promotional communications, such as those related to your account, the Services you've requested, or our ongoing business relationship.
                </p>

                <h6 className="text-lg font-semibold text-gray-700 mb-2">Changes to the Policy</h6>
                <p className="text-base leading-7 text-gray-700 mb-6 text-justify">
                    We may update this Policy periodically. By continuing to use the Services after any changes, you are agreeing to the revised terms. We recommend that you review this Policy regularly to stay informed about our latest privacy practices.
                </p>

                <h6 className="text-lg font-semibold text-gray-700 mb-2">Contact Us</h6>
                <p className="text-base leading-7 text-gray-700 mb-6 text-justify">
                    If you have any questions about this Policy, please contact us at <a href='mailto:sales@gotranslogistics.in' className="text-blue-500">sales@gotranslogistics.in</a>.
                </p>
            </div>
        </motion.div>
    );
}

export default PrivacyPolicy;
