import { useState } from "react";
//import Truck from "../../assets/img/Trucks.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { motion } from 'framer-motion';
import { Helmet } from "react-helmet";

function ContactUs() {
    const formInitialDetails = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: ''
    };

    const [formDetails, setFormDetails] = useState(formInitialDetails);
    const [buttonText, setButtonText] = useState('Send');
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isError, setIsError] = useState(false);

    const onFormUpdate = (category, value) => {
        setFormDetails({
            ...formDetails,
            [category]: value
        });
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setIsError(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setButtonText("Sending...");
        let response = await fetch(`${process.env.REACT_APP_POST_URL}/api/contact`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(formDetails),
        });
        setButtonText("Send");
        let result = await response.json();
        setFormDetails(formInitialDetails);

        if (result.code === 200) {
            setModalMessage('Message sent successfully.');
            setIsError(false);
        } else {
            setModalMessage('Something went wrong, please try again later.');
            setIsError(true);
        }

        setShowModal(true);
    };

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0, transition: { duration: 0.2 } }}>
            <Helmet>
                <title>Go Trans Logistics - Contact Us</title>
                <meta name="description" content="Contact us for any questions or inquiries." />
                <link rel="canonical" href="https://gotranslogistics.in/contactus" />
            </Helmet>
            <section className="relative flex flex-row justify-center items-center min-h-screen w-full bg-white px-5">
                {/* Image Section - Behind the form on small screens */}
                <div className="absolute inset-0 md:static md:w-1/2 opacity-40 md:opacity-100 z-1 flex justify-center items-center">
                    <TrackVisibility>
                        {({ isVisible }) =>
                            <img className={`${isVisible ? "animate__animated animate__zoomIn" : ""} w-full h-auto md:w-full object-contain`} src={"https://res.cloudinary.com/di6sgq793/image/upload/v1729523716/Trucks_vttj4i.webp"} alt="Contact Us" />
                        }
                    </TrackVisibility>
                </div>

                {/* Form Section */}
                <div className="relative z-10 w-full lg:w-1/3 md:w-1/2 mt-20 md:mt-10 animate__animated animate__fadeIn bg-transparent p-6 md:bg-transparent">
                    <h2 className="lg:text-6xl font-bold mb-6 text-center md:text-left text-4xl">Get In Touch</h2>
                    <form onSubmit={handleSubmit} className="space-y-4">
                        {/* Input Fields */}
                        <input type="text" value={formDetails.firstName} placeholder="First Name" onChange={(e) => onFormUpdate('firstName', e.target.value)} required className="w-full bg-transparent border-2 border-black rounded-lg py-2 px-4 text-lg text-black placeholder-black focus:outline-none focus:border-black transition duration-150" />
                        <input type="text" value={formDetails.lastName} placeholder="Last Name" onChange={(e) => onFormUpdate('lastName', e.target.value)} required className="w-full bg-transparent border-2 border-black rounded-lg py-2 px-4 text-lg text-black placeholder-black focus:outline-none focus:border-black transition duration-150" />
                        <input type="email" value={formDetails.email} placeholder="Email Address" onChange={(e) => onFormUpdate('email', e.target.value)} required className="w-full bg-transparent border-2 border-black rounded-lg py-2 px-4 text-lg text-black placeholder-black focus:outline-none focus:border-black transition duration-150" />
                        <input type="tel" value={formDetails.phone} placeholder="Phone Number" onChange={(e) => onFormUpdate('phone', e.target.value)} maxLength={10} required className="w-full bg-transparent border-2 border-black rounded-lg py-2 px-4 text-lg text-black placeholder-black focus:outline-none focus:border-black transition duration-150" />
                        <textarea rows="6" value={formDetails.message} placeholder="Message" onChange={(e) => onFormUpdate('message', e.target.value)} required className="w-full bg-transparent border-2 border-black rounded-lg py-2 px-4 text-lg text-black placeholder-black focus:outline-none focus:border-black transition duration-150"></textarea>
                        <button type="submit" className="relative mt-6 py-2 px-6 bg-white border-2 border-black text-black text-lg font-bold uppercase tracking-wider overflow-hidden transition ease-in-out duration-300 group hover:bg-black hover:text-white">
                            <span className="relative z-10">{buttonText}</span>
                            <div className="absolute inset-0 bg-black w-0 h-full transition-all duration-300 ease-in-out group-hover:w-full"></div>
                        </button>
                    </form>
                </div>
            </section>
            <p className="text-center text-red-500 mt-6">Note:- For any feedback or query please fill the contact form above.</p>

            {/* Modal for success or error message */}
            {showModal && (
                <div className="fixed inset-0 bg-opacity-90 flex items-center justify-center z-50">
                    {/* Blur effect on background */}
                    <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-sm"></div>

                    {/* Modal content */}
                    <div className="relative z-60 bg-white p-6 space-y-4 text-center shadow-lg">
                        <h3 className={`${isError ? 'text-red-500' : 'text-green-500'} text-xl font-bold`}>
                            {isError ? 'Error' : 'Success'}
                        </h3>
                        <p className="text-lg">{modalMessage}</p>
                        <button className="mt-4 py-2 px-4 bg-black text-white hover:bg-gray-800 transition hover:text-black hover:bg-white hover:border-2 hover:border-black" onClick={handleCloseModal}>
                            Close
                        </button>
                    </div>
                </div>
            )}


        </motion.div>
    );
}

export default ContactUs;
