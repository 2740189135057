import React from 'react';
//import logo from "../assets/img/logo.png";
import { NavLink } from "react-router-dom";
import { FaPhone, FaEnvelope, FaLinkedin, FaInstagram, FaFacebook} from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";

function Footer() {
    return (
        <footer className="bg-[#222] text-white py-10 w-full">
            <div className="max-w-6xl mx-auto px-4">
                <div className="flex flex-col md:flex-row justify-between items-center md:items-start flex-wrap">
                    {/* Logo and Slogan */}
                    <div className="flex flex-col items-center md:items-start text-center md:text-left max-w-md mb-8 md:mb-0">
                        <img src={"https://res.cloudinary.com/di6sgq793/image/upload/v1729523713/logo_x3ue7s.webp"} className="h-24 mb-4" alt="logo" />
                        <p className="text-gray-400 text-sm text-justify">
                            A new wave of logistics solutions, designed specifically for varying container needs - 20 Ft, 22 Ft, 32 Ft SXL, and 32 Ft MXL.
                        </p>
                    </div>

                    {/* Footer Navigation - Beside logo on larger screens and centered on small screens */}
                    <div className="flex flex-col md:flex-row gap-8 md:gap-16 items-center md:items-start text-center md:text-left text-justify">
                        {/* Contact Information */}
                        <div className="flex flex-col items-center md:items-start">
                            <h3 className="text-lg font-semibold mb-4">Contact</h3>
                            <a href="tel:+919693094957" className="text-gray-400 flex items-justify gap-2"><FaPhone /> +91 9693094957</a>
                            <a href="mailto:sales@gotranslogistics.in" className="text-gray-400 flex items-justify gap-2"><FaEnvelope /> sales@gotranslogistics.in</a>
                            <p className="text-gray-400 flex items-justify gap-2 max-w-md md:max-w-60"> <IoLocationOutline className='text-4xl' /> Office No. 1A, 3rd floor, 109/A/19 Girish Ghosh Road (Belur), Howrah 711202, West-Bengal</p>
                        </div>

                        {/* Follow Us Section */}
                        <div className="flex flex-col items-center md:items-start">
                            <h3 className="text-lg font-semibold mb-4">Follow Us</h3>
                            <a href="https://www.linkedin.com/company/go-trans-logistics" className="text-gray-400 flex items-justify gap-2"><FaLinkedin /> LinkedIn</a>
                            <a href="https://www.instagram.com/gotranslogistics.in/" className="text-gray-400 flex items-justify gap-2"><FaInstagram /> Instagram</a>
                            <a href="/" className="text-gray-400 flex items-justify gap-2"><FaFacebook /> Facebook</a>
                        </div>

                        {/* Legal Section */}
                        <div className="flex flex-col items-center md:items-start">
                            <h3 className="text-lg font-semibold mb-4">Legal</h3>
                            <NavLink to="/privacyandpolicy" className="text-gray-400">Privacy and Policy</NavLink>
                            <NavLink to="/termsandconditions" className="text-gray-400">Terms of Service</NavLink>
                        </div>
                    </div>
                </div>

                {/* Footer Bottom */}
                <div className="mt-8 text-center border-t border-gray-700 pt-4 text-sm">
                    <p className="text-gray-400">&copy; 2024 Go Trans Logistics. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
